import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Chromelp4step from "src/components/chromelp4step"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import Btf_cb from "src/components/btf_cb"


const chromelpData = import("./data/data.json");
const btfData = import("./data/btfData.json");

export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
       body {
         height: 100vh;
         width: 100%;
         min-width: 728px;
         min-height: 700px;
         background: #E1EEF8;
         background: linear-gradient(to top right, #E1EEF8, #F7FCFF);
         padding: 0;
         margin: 0;
         background-repeat: no-repeat;
         background-size: cover;
         font-family: 'Roboto', sans-serif;
       }

       .footerlinks-module--br {
         display: none!important;
       }

       .btf_cb-module--btfCb button.btf_cb-module--install {
          border: none;
          background: #0078D4;
          color: #fff;
          font-weight: 500;
          font-size: 20pt;
          padding: 15px 50px;
          border-radius: 50px;
          cursor: pointer;
          letter-spacing: .5px;
          transition: .2s ease;
          margin: 25px auto;
          max-width: 300px;
       }

       #chromelp-module--chromelp {
         height: 100vh;
       }

       #chromelp-module--chromelp .chromelp-module--main {
         margin: 100px auto 0;
       }

   .troubleDL {
    height:100vh;
    display:absolute;
    top: 0px;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    z-index: 100;
    pointer-events: auto;
  }

.troubleDL h1 {
    position: absolute;
    top:30%;
    left: 15%;
    text-align:center;
    font-size: 35pt;
  }
    .troubleDL h1 span {
        display:block;
        font-size: 24pt;
      }
    .troubleDL h1 span span {
      color:#0078d4;
      text-decoration:underline;
      }

    `}
    </style>
    <style type="text/css">
         </style>
    <title>Print any web page easily - Easy Print</title></Helmet>
    <section>
      <Chromelp4step data={chromelpData}>
      </Chromelp4step>
      <Btf_cb data={btfData}></Btf_cb>
      </section>
    </HomepageLayout>
  )
}
